<template>
  <div
    id="compareCard"
    :ref="'compareCard-' + sceneIndex"
    class="d-flex flex-column align-items-center h-100"
  >
    <div
      class="card-scene-parent w-100"
      :ref="'modelIDContainer-' + parseModelID"
      :style="{ 'border-color': highlightSceneColor + '!important' }"
      @mouseenter.stop="compareCardHighlight($event, true)"
      @mouseleave.stop="compareCardHighlight($event, false)"
    >
      <div class="card-scene w-100" :ref="sceneIndex" :id="sceneIndex"></div>
      <div class="card-scene-buttons d-flex justify-content-end">
        <div class="icon-svg mr-2">
          <collectionHeart :currentModelID="parseModelID" />
        </div>
        <div class="icon-svg mr-2">
          <exploreTelescope :currentModelID="parseModelID" />
        </div>
      </div>
    </div>
    <div class="card-metric-panel d-flex flex-column flex-grow-1 w-100">
      <!-- <div id="metric-inputs-container" class="d-flex align-items-center flex-wrap justify-content-between">
        <div class="input-label d-flex align-items-center m-1" v-for="(item, index) in inputArray" :key="index">
          <div class="sublabel">{{item.label}}: </div>
          <div class="label ml-2">{{item.value | getInputLabel(item.key, item.marks) }}  </div>
        </div>
      </div> -->
      <div
        id="metric-outputs-container"
        class="d-flex flex-column align-items-stretch justify-content-around flex-grow-1"
      >
        <div
          class="metric-panel d-flex justify-content-between"
          v-for="metric in metricCard"
          :key="'card-' + metric.label"
        >
          <div class="metric-label text-left mr-4">
            <!-- <h3 class="title">{{metric.prettyValue}}</h3> -->
            <!-- <div class="label">{{metric.label}}</div> -->
            <div class="table-label">{{ metric.prettyValue }}</div>
            <!-- <div class="units-text">{{metric.units}}</div> -->
          </div>
          <div class="star-parent d-flex align-items-start">
            <star
              v-for="(star, index) in metric.starRatingArray"
              :key="index"
              :typeOfStar="star"
              :aboveBenchmark="metric.benchmark"
              :width="20"
              :height="20"
              class="align-self-center"
              :starColor="metric.compareStatus"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @vue-import heart child component
 */
import collectionHeart from '@/components/Utility/collection_heart.vue'
import exploreTelescope from '@/components/Utility/explore_telescope.vue'

// star template
import star from '@/components/Utility/star.vue'

export default {
  name: 'compareCard',
  props: {
    modelObject: {
      type: Object
    }
  },
  components: {
    star,
    collectionHeart,
    exploreTelescope
  },
  computed: {
    sceneIndex() {
      return this.modelObject['sceneIndex'] + this.modelObject['selectedModel']
    },
    parseModelID() {
      return parseInt(this.modelObject['modelID'])
    },
    metricCard() {
      return this.modelObject['metricCard']
    },
    highlightSceneColor() {
      return this.modelObject['highlightScene'] === true
        ? this.modelObject['color']
        : this.modelObject['defaultColor']
    },
    sceneWidth() {
      return this.$refs['compareCard-' + this.sceneIndex] === undefined
        ? '200px'
        : this.$refs['compareCard-' + this.sceneIndex].clientWidth
    }
  },
  methods: {
    compareCardHighlight(event, toggle) {
      event.preventDefault()
      this.$emit('compareCardHightlight', [this.modelObject.index, toggle])
    }
  }
}
</script>

<style lang="scss">
$card-size: 250px;
// $card-width: calc(#{$card-size + 100px});

#compareCard {
  .card-scene-parent {
    border-width: 2px;
    border-style: solid;
    border-radius: 6px;
  }
  .card-scene {
    height: $card-size;
    // width: $card-width;
    // min-width: $card-width;
    background: transparent;
  }
  .metric-panel {
    border-bottom: 1px solid $dark-grey;
  }
  .card-metric-panel {
    height: $card-size;
  }
  .card-scene-buttons {
    position: relative;
    bottom: $font-md;
    right: $font-md;
  }
  #metric-inputs-container {
    margin-bottom: 0.5rem;
    border-bottom: 1px solid $white;
  }
}
</style>
